import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/jB-UCkTqERg"
    bibleGroupSrc="https://pdfhost.io/v/eisyQ7liF_Microsoft_Word_Bible_Group_Homework_1213docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Treating Others - Holiday on its Head" />
  </Layout>
)

export default SermonPost
